import React from 'react'

// Components
import GalleryStatic from 'components/flex/Gallery/GalleryStatic'

interface GalleryShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Text
  location?: any
}

interface TextProps {
  [key: string]: any
}

const GalleryShell: React.FC<GalleryShellProps> = ({
  fields,
  location = {},
}) => {
  const gallery: TextProps = {
    static: GalleryStatic,
  }

  if (!fields.styletype || !gallery[fields.styletype]) {
    return null
  }

  const Component = gallery[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default GalleryShell
