import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: 325px;
  }
  @media (max-width: 575px) {
    height: 250px;
  }
`

interface GalleryStaticProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}

const GalleryStatic: React.FC<GalleryStaticProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Content content={fields.description} className="mb-5" />
          <div className="row">
            {fields.gallery?.map((image, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
                <Image
                  image={image}
                  alt={image?.altText || 'Gallerij afbeelding'}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default GalleryStatic
